import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@layouts/Layout'
import { useIntl } from 'gatsby-plugin-intl'
import Button from '@components/buttons/Button'
import GroupButtons from '@components/group_buttons/GroupButtons'
import { Section1, Section2, Section3, Section4, Section5, Section6, Section7 } from '@templates/security-companions'
import { navigate } from '@reach/router'
import { iconProtection, iconVirtualPrinter, iconProtectionPlus, iconMonitoring, iconAuthentication } from '@icons'

const SecurityCompanions = props => {
  const { pageContext } = props
  const { languageName, language } = pageContext
  const { formatMessage } = useIntl()

  const Buttons = () => {
    return (<GroupButtons>
      <Button href={`/${language}/features`}>{formatMessage({ id: 'learn-more' })}</Button>
      <Button href={`/${language}/download`}>{formatMessage({ id: 'download-trial' })}</Button>
    </GroupButtons>)
  }

  const Content = options => {
    const { id } = options
    return <div dangerouslySetInnerHTML={{ __html: formatMessage({ id }) }} />
  }

  const flipCardDataItems = [
    {
      icon: iconProtection,
      title: 'Essential Proteccion',
      description: formatMessage({ id: 'security-companions-section-2-security-essentials-description' }),
      onClick: () => {
        navigate(`/${language}/security-companions#security-essentials`)
      }
    },
    {
      icon: iconVirtualPrinter,
      title: 'Virtual Printer',
      description: formatMessage({ id: 'security-companions-section-2-virtual-printer-description' }),
      onClick: () => {
        navigate(`/${language}/security-companions#virtual-printer`)
      }
    },
    {
      icon: iconProtectionPlus,
      title: 'Ultimate Protection',
      description: formatMessage({ id: 'security-companions-section-2-ultimate-protection-description' }),
      onClick: () => {
        navigate(`/${language}/security-companions#ultimate-protection`)
      }
    },
    {
      icon: iconAuthentication,
      title: 'Two Factor Authentication - 2FA',
      description: formatMessage({ id: 'security-companions-section-2-two-factor-authentication-2fa' }),
      onClick: () => {
        navigate(`/${language}/security-companions#two-factor-authentication-2fa`)
      }
    },
    {
      icon: iconMonitoring,
      title: 'Server Monitoring',
      description: formatMessage({ id: 'security-companions-section-2-server-monitoring' }),
      onClick: () => {
        navigate(`/${language}/security-companions#server-monitoring`)
      }
    }
  ]

  return (<Layout languageName={languageName} pageContext={pageContext}>
    <div className='row'>
      <Section1
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-1-title' })}
        id='companion-tools' language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-1-content'/>
      </Section1>
      <Section2
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-2-title' })}
        id='tsplus-companion-tools'
        language={language}
        buttons={<Buttons/>}
        data={flipCardDataItems}
      >
        <p><Content id='security-companions-section-2-content'/></p>
      </Section2>
      <Section3
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-3-title' })}
        id='security-essentials'
        language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-3-content'/>
      </Section3>
      <Section4
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-4-title' })}
        id='ultimate-protection'
        language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-4-content'/>
      </Section4>
      <Section5
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-5-title' })}
        id='two-factor-authentication-2fa'
        language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-5-content'/>
      </Section5>
      <Section6
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-6-title' })}
        id='server-monitoring'
        language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-6-content'/>
      </Section6>
      <Section7
        className='col-md-12'
        title={formatMessage({ id: 'security-companions-section-7-title' })}
        id='virtual-printer'
        language={language}
        buttons={<Buttons/>}
      >
        <Content id='security-companions-section-7-content'/>
      </Section7>
    </div>
  </Layout>)
}

SecurityCompanions.propTypes = {
  pageContext: PropTypes.any
}

export default SecurityCompanions
