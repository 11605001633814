import React from 'react'
import styles from './FlipCard.module.scss'

const FlipCard = props => {
  const { icon, title, description, extra, onClick } = props
  return (<div className={styles.flipCard} onClick={onClick}>
    <div className={styles.flipCardInner}>
      <div className={styles.flipCardFront}>
        <img src={icon}/>
        <p>{title}</p>
      </div>
      <div className={styles.flipCardBack}>
        <img src={icon}/>
        <p>
          {description}
          <br/>
          {extra}
        </p>
      </div>
    </div>
  </div>)
}

export default FlipCard
