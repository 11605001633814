import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section3.module.scss'
import Button from '@components/buttons/Button'
import GroupButtons from '@components/group_buttons/GroupButtons'
import { logo, essentielProtection, smallBuildings } from '@images'
import { Fade, Reveal } from 'react-reveal'

const Section3 = props => {
  const { className, children, title, id, language, buttons } = props
  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className={['col-sm-6', 'text-right', styles.printerImage].join(' ')}>
        <Fade bottom>
          <img src={essentielProtection} alt="tsplus application screenshot" width="300"/>
        </Fade>
      </div>
      <div className="col-sm-6">
        <Fade top big>
          <img className="d-none d-sm-inline mb-3" src={logo} alt="tsplus logo" width="232"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
    </div>
    <div className={styles.shapes}>
      <div className={styles.image}>
        <Reveal>
          <img src={smallBuildings} alt="small buildings background"/>
        </Reveal>
      </div>
    </div>
  </div>)
}

Section3.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string
}

export default Section3
