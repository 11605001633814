import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section2.module.scss'
import FlipCard from '@components/cards/FlipCard'


const Section2 = props => {
  const { className, children, title, id, language, buttons, data: flipCardDataItems = [] } = props

  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className='text-center'>
      <div className={styles.title}>{title}</div>
    </div>
    <div className='container'>
      <div className='row justify-content-md-center'>
        { flipCardDataItems.map((item, index) => {
          return (<div className='col-md-6' key={index}>
            <FlipCard {...item}/>
          </div>)
        }) }
      </div>
      <div className={['text-center', styles.extra].join(' ')}>
        {children}
        {buttons}
      </div>
    </div>
  </div>)
}

Section2.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string,
  language: PropTypes.string
}

export default Section2
